import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes =  [
  {
    path: "/",
    alias: "/public-collectioins",
    name: "PublicCollectionScreen",
    component: () => import("./components/PublicCollectionScreen")
  },
  {
    path: "/signin",
    name: "LoginScreen",
    // component: () => import("./components/LoginScreen")
    component: () => import("./components/AuthLogin")
  },
  {
    path: "/signup",
    name: "UserProfileCreate",
    component: () => import("./components/UserProfileCreate")
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("./components/WelcomeScreen")
  },
  {
    path: "/password-forgot",
    name: "ForgotPassword",
    component: () => import("./components/AuthForgot")
  },
  {
    path: "/password-reset/",
    name: "ResetPassword",
    component: () => import("./components/AuthReset")
  },
  {
    path: "/brands",
    name: "BrandIndex",
    component: () => import("./components/BrandIndex")
  },
  {
    path: "/brands/create",
    name: "BrandCreateScreen",
    component: () => import("./components/BrandCreateScreen"),
    props: true
  },
  {
    path: "/brands/:id/update",
    name: "BrandUpdateScreen",
    component: () => import("./components/BrandUpdateScreen"),
    props: true
  },
  {
    path: "/brands/:id/show",
    name: "BrandShowScreen",
    component: () => import("./components/BrandShowScreen"),
    props: true
  },
  {
    path: "/product-models",
    name: "ProductModelIndex",
    component: () => import("./components/ProductModelIndex")
  },
  {
    path: "/product-models/create",
    name: "ProductModelCreateScreen",
    component: () => import("./components/ProductModelCreateScreen"),
    props: true
  },
  {
    path: "/product-models/:id/update",
    name: "ProductModelUpdateScreen",
    component: () => import("./components/ProductModelUpdateScreen"),
    props: true
  },
  {
    path: "/product-models/:id/show",
    name: "ProductModelShowScreen",
    component: () => import("./components/ProductModelShowScreen"),
    props: true
  },
  {
    path: "/product-listings",
    name: "ProductListingIndex",
    component: () => import("./components/ProductListingIndex")
  },
  {
    path: "/product-listings/create",
    name: "ProductListingCreateScreen",
    component: () => import("./components/ProductListingCreateScreen"),
    props: true
  },
  {
    path: "/product-listings/:id/update",
    name: "ProductListingUpdateScreen",
    component: () => import("./components/ProductListingUpdateScreen"),
    props: true
  },
  {
    path: "/product-listings/:id/show",
    name: "ProductListingShowScreen",
    component: () => import("./components/ProductListingShowScreen"),
    props: true
  },
  {
    path: "/product-items",
    name: "ProductItemIndex",
    component: () => import("./components/ProductItemIndex")
  },
  {
    path: "/product-items/create",
    name: "ProductItemCreateScreen",
    component: () => import("./components/ProductItemCreateScreen"),
    props: true
  },
  {
    path: "/product-items/:id/update",
    name: "ProductItemUpdateScreen",
    component: () => import("./components/ProductItemUpdateScreen"),
    props: true
  },
  {
    path: "/product-items/:id/show",
    name: "ProductItemShowScreen",
    component: () => import("./components/ProductItemShowScreen"),
    props: true
  },
  {
    path: "/product-item-links",
    name: "ProductItemLinkIndex",
    component: () => import("./components/ProductItemLinkIndex")
  },
  {
    path: "/product-item-links/create",
    name: "ProductItemLinkCreate",
    component: () => import("./components/ProductItemLinkCreate"),
    props: true
  },
  {
    path: "/product-item-links/:id/update",
    name: "ProductItemLinkUpdate",
    component: () => import("./components/ProductItemLinkUpdate"),
    props: true
  },
  {
    path: "/collections/:id/screen",
    name: "CollectionScreen",
    component: () => import("./components/CollectionScreen"),
    props: true
  },
  {
    path: "/public-collections",
    name: "PublicCollectionScreen",
    component: () => import("./components/PublicCollectionScreen")
  },
  {
    path: "/collections",
    name: "CollectionIndex",
    component: () => import("./components/CollectionIndex")
  },
  {
    path: "/collections/create",
    name: "CollectionCreate",
    component: () => import("./components/CollectionCreate"),
    props: true
  },
  {
    path: "/collections/:id/update",
    name: "CollectionUpdateScreen",
    component: () => import("./components/CollectionUpdateScreen"),
    props: true
  },
  {
    path: "/collections/:id/show",
    name: "CollectionShowScreen",
    component: () => import("./components/CollectionShowScreen"),
    props: true
  },
  {
    path: "/collection-images/:id/update",
    name: "CollectionImageUpdateScreen",
    component: () => import("./components/CollectionImageUpdateScreen"),
    props: true
  },
  {
    path: "/collection-images/create",
    name: "CollectionImageCreateScreen",
    component: () => import("./components/CollectionImageCreateScreen"),
    props: true
  },
  {
    path: "/collection-images/:id/show",
    name: "CollectionImageShowScreen",
    component: () => import("./components/CollectionImageShowScreen"),
    props: true
  },
  {
    path: "/item-image-associations",
    name: "ItemImageAssociationIndex",
    component: () => import("./components/ItemImageAssociationIndex"),
  },
  {
    path: "/item-image-associations/:collection_id/screen",
    name: "ItemImageAssociationScreen",
    component: () => import("./components/ItemImageAssociationScreen"),
    props: true
  },
  {
    path: "/work-groups",
    name: "WorkGroupIndex",
    component: () => import("./components/WorkGroupIndex")
  },
  {
    path: "/work-groups/create",
    name: "WorkGroupCreate",
    component: () => import("./components/WorkGroupCreate"),
    props: true
  },
  {
    path: "/work-groups/:id/show",
    name: "WorkGroupShowScreen",
    component: () => import("./components/WorkGroupShowScreen"),
    props: true
  },
  {
    path: "/work-groups/:id/update",
    name: "WorkGroupUpdateScreen",
    component: () => import("./components/WorkGroupUpdateScreen"),
    props: true
  },
  {
    path: "/work-groups/:id/manage",
    name: "WorkGroupManage",
    component: () => import("./components/WorkGroupManage"),
    props: true
  },
  {
    path: "/actor-roles",
    name: "ActorRoleIndex",
    component: () => import("./components/ActorRoleIndex")
  },
  {
    path: "/actor-roles/create",
    name: "ActorRoleCreateScreen",
    component: () => import("./components/ActorRoleCreateScreen"),
    props: true
  },
  {
    path: "/actor-roles/:id/update",
    name: "ActorRoleUpdateScreen",
    component: () => import("./components/ActorRoleUpdateScreen"),
    props: true
  },
  {
    path: "/actor-roles/:id/show",
    name: "ActorRoleShowScreen",
    component: () => import("./components/ActorRoleShowScreen"),
    props: true
  },
  {
    path: "/profiles",
    name: "ProfileIndex",
    component: () => import("./components/ProfileIndex")
  },
  {
    path: "/profiles/create",
    name: "ProfileCreate",
    component: () => import("./components/ProfileCreate"),
    props: true
  },
  {
    path: "/profiles/:id/update",
    name: "ProfileUpdateScreen",
    component: () => import("./components/ProfileUpdateScreen"),
    props: true
  },
  {
    path: "/profiles/:id/show",
    name: "ProfileShowScreen",
    component: () => import("./components/ProfileShowScreen"),
    props: true
  },
  {
    path: "/users",
    name: "UserIndex",
    component: () => import("./components/UserIndex")
  },
  {
    path: "/users/create",
    name: "UserCreate",
    component: () => import("./components/UserCreate"),
    props: true
  },
  {
    path: "/users/:id/update",
    name: "UserUpdateScreen",
    component: () => import("./components/UserUpdateScreen"),
    props: true
  },
  {
    path: "/users/:id/show",
    name: "UserShowScreen",
    component: () => import("./components/UserShowScreen"),
    props: true
  },
  {
    path: "/comics",
    name: "ComicsScreen",
    component: () => import("./components/ComicsScreen")
  }
]

const router = new Router({
  mode: 'history',
  routes,
})

export default router
