const query_path = {
  namespaced: true,
  state: {
    path: [],
  },
  mutations: {
    add_query (state, breadcrumb) {
      state.path.push(breadcrumb)
    },
    clear_path(state) {
      state.path = []
    }
  },
  actions: {
    start_trail ({ commit }, breadcrumb) {
      commit('clear_path')
      commit('add_query', breadcrumb)
    },
    follow_trail ({commit}, breadcrumb) {
      commit('add_query', breadcrumb)
    }
  },
  getters: {
    trail (state) {
      return state.path
    }
  }
}

export default query_path