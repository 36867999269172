const user = {
  namespaced: true,
  state: {
    current_user: {},
    user_roles: []
  },
  mutations: {
    set (state, user) {
      state.current_user = user
    },
    set_roles(state, roles) {
      state.user_roles = roles
    }
  },
  actions: {
    set_current( {commit}, user) {
      commit('set', user)
    },
    set_roles( {commit}, roles) {
      commit('set_roles', roles)
    }
  },
  getters: {
    user (state) {
      return state.current_user
    },
    roles (state) {
      return state.user_roles
    }
  }
}

export default user