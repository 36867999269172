import Vue from 'vue'
import Vuex from 'vuex'

import query_path from './query_path'
import cached_products from './cached_products'
import user from './user'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    query_path,
    cached_products,
    user
  }
})

export default store