<template>
<div>
  <b-navbar toggleable="lg" type="dark" variant="primary">
    <b-navbar-brand to="/public-collections">Collectables</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/brands">Brands</b-nav-item>
        <b-nav-item to="/product-models">Products</b-nav-item>
        <b-nav-item to="/product-listings">Listings</b-nav-item>
        <b-nav-item to="/product-items">Items</b-nav-item>
        <!-- <b-nav-item to="/product-item-links">Links</b-nav-item>
        <b-nav-item to="/item-image-associations">Associations</b-nav-item> -->
        <b-nav-item to="/collections">Collections</b-nav-item>
        <b-nav-item to="/work-groups">Teams</b-nav-item>
        <b-nav-item to="/actor-roles">Actors</b-nav-item>
        <b-nav-item to="/profiles">Profiles</b-nav-item>
        <b-nav-item to="/users">Users</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
          <b-button size="sm" type="submit">Search</b-button>
        </b-nav-form>

        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item to="/signup">Sign Up</b-dropdown-item>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item to="/signin">Sign In</b-dropdown-item>
          <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  methods: {
    logout() {
        http_v1.delete (
        'logout', {
        }
      ).then(() => {
          this.$router.push({path: '/signin'})
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    }
  }
}
</script>
