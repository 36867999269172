const cached_products = {
  namespaced: true,
  state: {
    products: []
  },
  mutations: {
    add_only_new_product (state, product) {
     state.products.find( 
        el =>  
        el.type === product.type && el.id === product.id
      ) || state.products.push(product)
    }
  },
  actions: {
    add_product ({commit}, product) {
      commit('add_only_new_product', product)
    },
    add_products ({commit}, products) {
      products?.forEach( el => {
        commit('add_only_new_product', el)
      })
    }
  },
  getters: {
    // homes.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    // .sort(
    //   (a,b) => a.attributes['name'].localCompare(b.attributes['name'])
    // )
    brands (state) {
      return state.products.filter(
        el => el.type === 'brands'
      )
    },
    product_models (state) {
      return state.products.filter(
        el => el.type === 'product-models'
      )
    },
    product_listings (state) {
      return state.products.filter(
        el => el.type === 'product-listings'
      )
    },
    product_items (state ) {
      return state.products.filter(
        el => el.type === 'product-items'
      )
    }
  }
}

export default cached_products