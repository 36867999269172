import axios from 'axios'

export const http_v1 = axios.create({
  // baseURL: process.env.NODE_ENV !== 'production' ? `http://localhost:3000` : `/`,
  // baseURL: process.env.NODE_ENV !== 'production' ? `http://192.168.1.7:3000` : `http://192.168.1.7:3000`,
  // baseURL: process.env.NODE_ENV !== 'production' ? `http://3.142.143.180:3000` : `http://192.168.1.7:3000`,
  // AWS 
  // baseURL: 'http://3.142.143.180:3000',
  baseURL: 'https://restful.unicorn-farm.com',
  headers: {
    'Authorization': localStorage.token,
  }
})

http_v1.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
);
