import Vue from "vue"
import router from './router'
import store from './store'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import App from "./App.vue"

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// install rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
// https://stackoverflow.com/questions/59156440/how-to-validate-decimal-value-in-vee-validate-3-0-version
// extend("decimal", {
//   validate: (value, { decimals = '2', separator = '.' } = {}) => {
//     if (value === null || value === undefined || value === '') {
//       return {
//         valid: false
//       };
//     }
//     if (Number(decimals) === 0) {
//       return {
//         valid: /^-?\d*$/.test(value),
//       };
//     }
//     const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
//     const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

//     return {
//       valid: regex.test(value),
//     };
//   },
//   message: 'The {_field_} field must contain only decimal values with precision of 2'
// })
// Install components globally
Vue.use(BootstrapVue)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")